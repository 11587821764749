#chapter h1, #chapter h2, #chapter h3, #chapter h4, #chapter h5 {
  margin-bottom: 10px;
  text-align: left !important;
}

#chapter ul:not(.tf-result *) {
  margin: 14px 0;
}

#refs:before {
  content: "References";
  margin-top: 33px;
  margin-bottom: 7px;
  font-size: 15px;
  font-weight: 500;
  display: block;
}

#menu a {
  color: #696969;
}

#menu .current a {
  color: #000;
  font-weight: 500;
}

#refs {
  text-indent: -30px;
  margin-left: 30px;
}

.csl-entry {
  padding-bottom: 4px;
}

#chapter-add {
  border: 1px solid silver;
}

.chapter-edit:empty {
  min-height: 30px;
  border: 1px dotted silver;
  border-radius: 3px;
}

.md-edit {
  position: relative;
  top: 6px;
}

#chapter-number {
  width: 40px;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 1px 3px;
  font-size: 12px;
}

#main-bibliography > p, #main-bibliography #refs:before {
  display: none;
}

/*# sourceMappingURL=index.fb336c88.css.map */
