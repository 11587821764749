#chapter h1,
#chapter h2,
#chapter h3,
#chapter h4,
#chapter h5 {
  text-align: left !important;
  margin-bottom: 10px;
}

#chapter ul:not(.tf-result *) {
  margin: 14px 0;
}

#refs:before {
  content: "References";
  display: block;
  font-weight: 500;
  font-size: 15px;
  margin-top: 33px;
  margin-bottom: 7px;
}

#menu a {
  color: dimgrey;
}

#menu .current a {
  color: black;
  font-weight: 500;
}

#refs {
  text-indent: -30px;
  margin-left: 30px;
}

.csl-entry {
  padding-bottom: 4px;
}

#chapter-add {
  border: 1px solid silver;
}

.chapter-edit:empty {
  min-height: 30px;
  border: 1px dotted silver;
  border-radius: 3px;
}

.md-edit {
  position: relative;
  top: 6px;
}

#chapter-number {
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 1px 3px;
  font-size: 12px;
  width: 40px;
}

#main-bibliography > p {
  display: none;
}

#main-bibliography #refs:before {
  display: none;
}
